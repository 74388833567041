<script setup lang="ts">
import type { Route } from '@/types/route';
import { useRouterLink } from '@/composables/router';
import { useUserStore } from '@/stores/userStore';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

// This component is used as the main entry to the "Emails" tab subroutes are defined using router-view the the other compoenents in the parent folder

// setup
const routeComp = useRoute();
const emailsLink = useRouterLink('EmailsIndex');
const templatesLink = useRouterLink('TemplatesIndex');
const scheduledEmailsLink = useRouterLink('ScheduledIndex');
const sentEmailsLink = useRouterLink('SentEmailsIndex');
const smsCampaignsNewLink = useRouterLink('SmsCampaignsNew');
const userStore = useUserStore();

const isGmailDashboardSendingAllowed = computed(() => {
  return userStore.currentUser?.gmail_dashboard_sending_enabled && userStore?.currentUser?.send_mail_merge_campaigns_enabled;
});
const isOutlookadDashboardSendingAllowed = computed(() => {
  return (
    userStore.currentUser?.outlook_dashboard_sending_enabled
    && userStore.currentUser.send_mail_merge_campaigns_enabled
    && !userStore.currentUser.aggregate_send_only
  );
});

const isEmailsSlideoverOpen = ref(false);
function openEmailsSlideover() {
  isEmailsSlideoverOpen.value = true;
}
function closeEmailsSlideover() {
  isEmailsSlideoverOpen.value = false;
}

const routes = ref<Route[]>([
  {
    name: 'Email Library',
    to: emailsLink,
    current() { return routeComp.name === 'EmailsIndex'; },
  },
  {
    name: 'Sent',
    to: sentEmailsLink,
    current() { return routeComp.name === 'SentEmailsIndex'; },
  },
  {
    name: 'My Templates',
    to: templatesLink,
    current() { return routeComp.name === 'TemplatesIndex'; },
  },
]);

if (userStore.currentUser?.organization.scheduled_sends_enabled) {
  const scheduledTab = {
    name: 'Scheduled',
    to: scheduledEmailsLink,
    current() { return routeComp.name === 'ScheduledIndex'; },
  } satisfies Route;

  routes.value.splice(1, 0, scheduledTab);
}

const sentEmailBannerShow = ref(userStore.smsTrialUser);
const sentEmailBannerShouldShow = computed(() => {
  return sentEmailBannerShow.value || !(localStorage.getItem('sentEmailsBannerDismissedForever') === 'true');
});

function orgTeamBannerDismiss(forever: boolean) {
  sentEmailBannerShow.value = false;

  if (forever) {
    localStorage.setItem('sentEmailsBannerDismissedForever', 'true');
  }
}

function hideEmailBanner() {
  sentEmailBannerShow.value = false;
}
</script>

<template>
  <div>
    <Teleport to="#banner">
      <div
        v-show="sentEmailBannerShouldShow"
        class="h-12 flex border-b border-primary-900/20 bg-primary-50 text-primary-600"
      >
        <div class="mx-auto max-w-7xl flex flex-grow items-center justify-center px-4 lg:px-8 sm:px-6">
          <div class="flex flex-grow items-center justify-center truncate">
            <i-heroicons-sparkles-20-solid class="h-5 w-5 shrink-0" />
            <p class="ml-1 text-sm font-normal">
              Employee SMS is now available!
              <router-link
                class="text-primary-700 font-semibold"
                :to="smsCampaignsNewLink"
                @click="hideEmailBanner()"
              >
                Try it out
              </router-link>
            </p>
          </div>
          <div class="flex gap-1">
            <CButton
              size="sm"
              text
              button-type="primary"
              @click="orgTeamBannerDismiss(true)"
            >
              <i-heroicons-x-mark-20-solid />
            </CButton>
          </div>
        </div>
      </div>
    </Teleport>
    <div class="flex flex-col justify-between gap-y-4 md:flex-row">
      <div class="flex gap-x-4">
        <router-link
          v-for="route in routes"
          :key="route.name"
          class="rounded-md px-4 py-2 text-sm font-medium" :class="[{ 'bg-primary-100 text-primary-700': route.current() }, { 'text-slate-500 hover:bg-slate-100 hover:text-slate-700': !route.current() }]"
          :to="route.to"
        >
          <!-- The 'to' above is probably the part where this doesn't reload upon changing the OT -->
          {{ route.name }}
        </router-link>
      </div>
      <div class="flex gap-x-2">
        <CButton
          @click="openEmailsSlideover"
        >
          Create New Email
        </CButton>

        <EmailsSlideover
          v-model:is-slideover-open="isEmailsSlideoverOpen"
          :is-template-creation-mode="true"
          @update:show="closeEmailsSlideover"
        />

        <router-link
          v-if="isGmailDashboardSendingAllowed"
          v-tooltip:top="{
            content: 'You need to enable GSuite Integration from your Profile Settings',
            delay: 500,
            disabled: userStore.currentUser?.gsuite_connected,
          }"
          class="float-right"
          :to="{ name: 'GmailDashboardIndex', params: { orgId: routeComp.params.orgId } }"
        >
          <CButton
            button-type="secondary"
            leading-icon="i-heroicons:paper-airplane-solid"
            data-testid="ComposeSendButton"
            :disabled="!userStore.currentUser?.gsuite_connected"
          >
            Compose & Send
          </CButton>
        </router-link>

        <router-link
          v-if="isOutlookadDashboardSendingAllowed"
          class="float-right"
          :to="{ name: 'OutlookDashboardIndex', params: { orgId: routeComp.params.orgId } }"
        >
          <CButton
            button-type="secondary"
            data-testid="ComposeSendButton"
            leading-icon="i-heroicons:paper-airplane-solid"
          >
            Compose & Send
          </CButton>
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>
