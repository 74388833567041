<script setup lang="ts">
import { cmApi } from '@/api/instance';
import { useUserStore } from '@/stores/userStore';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const userStore = useUserStore();
const routeInfo = useRoute();
const baseURL = import.meta.env.VITE_API_BASE_URL;
const orgId = routeInfo.params.orgId;

const isLoading = ref(true);
const mmc = ref();
const mmcId = ref(routeInfo.params.mmc_id);
const dataOverviewLink = `${baseURL}/teams/${orgId}/stats/campaigns/${mmcId.value}`;
const segStatLink = `${baseURL}/teams/${orgId}/stats/campaigns/${mmcId.value}/show_segmented_reporting`;
const isDisabled = computed(() => !isLoading.value && Boolean(!mmc.value?.sent_at));

const loadMmc = async () => {
  const response = await cmApi.get(`/teams/${orgId}/mail_merge_campaigns/${mmcId.value}`);
  mmc.value = response.data.mail_merge_campaign;
  isLoading.value = false;
};

const railsTabs = computed(() => [
  {
    id: 'dataOverview',
    name: 'Data Overview',
    visible: true,
    disabled: isDisabled.value,
    href: dataOverviewLink,
  },
  {
    id: 'segmentedEmailStats',
    name: 'Segmented Email Stats',
    visible: !isLoading.value && mmc.value?.has_segmented_stats,
    disabled: isDisabled.value,
    href: segStatLink,
  },
]);

const vueTabs = computed(() => [
  {
    id: 'clickMap',
    name: 'Click Maps',
    visible: !isLoading.value && mmc.value?.click_maps_enabled,
    disabled: isDisabled.value,
    to: { name: 'ClickmapShow', params: { mmc_id: mmcId.value, orgId } },
    current() { return routeInfo.name === 'ClickmapShow'; },
  },
  {
    id: 'surveys',
    name: 'Surveys',
    visible: userStore.currentUser?.feature_flags?.ff_surveys_v2,
    disabled: isDisabled.value,
    to: { name: 'EmailsSurveys', params: { mmc_id: mmcId.value, orgId } },
    current() { return routeInfo.name === 'EmailsSurveys'; },
  },
  {
    id: 'socialChannels',
    name: 'Additional Channels',
    visible: userStore.socialChannelsUser,
    disabled: isDisabled.value,
    to: { name: 'SocialChannelsIndex', params: { mmc_id: mmcId.value, orgId } },
    current() { return routeInfo.name === 'SocialChannelsIndex'; },
  },
]);

watch(() => routeInfo.params.mmc_id, (id) => {
  if (id) {
    mmcId.value = id;
    loadMmc();
  }
}, { immediate: true });
</script>

<template>
  <div>
    <div class="mb-2">
      <div class="mb-5">
        <h2 class="text-4xl text-slate-600 font-bold">
          {{ mmc?.subject }}
        </h2>
      </div>

      <div class="w-full flex items-center">
        <span v-for="tab in railsTabs" :key="tab.id">
          <a
            v-if="tab.visible"
            class="mr-3 inline-block cursor-pointer rounded-md px-4 py-2 align-middle text-sm text-slate-500 font-semibold hover:bg-primary-50 hover:bg-slate-100 hover:text-primary-700"
            :class="{ 'pointer-events-none opacity-50': tab.disabled }"
            :href="tab.href"
          >
            {{ tab.name }}
          </a>

        </span>

        <div v-for="tab in vueTabs" :key="tab.id">
          <router-link
            v-if="tab.visible"
            :to="tab.to"
            class="mr-3 inline-block cursor-pointer rounded-md px-4 py-2 align-middle text-sm font-semibold"
            :class="[{ 'bg-primary-100 text-primary-700': tab.current() }, { 'text-slate-500 hover:bg-slate-100 hover:text-primary-700 hover:bg-primary-50': !tab.current() }, { 'pointer-events-none opacity-50': tab.disabled && !tab.current() }]"
            aria-current="page"
          >
            {{ tab.name }}
          </router-link>
        </div>
      </div>
    </div>
    <router-view :key="routeInfo.fullPath" :mmc="mmc" :is-loading-mmc="isLoading" />
  </div>
</template>
