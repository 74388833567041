<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  type?: string;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  hasError?: boolean;
  inputValue?: string | number;
  name?: string;
  passedRef?: string;
  lazy?: boolean;
  tooltipMessage?: string;
  leadingIcon?: string;
  trailingIcon?: string;
  preInput?: boolean;
  postInput?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  placeholder: '',
  label: '',
  errorMessage: '',
  hasError: false,
  inputValue: '',
  name: '',
  passedRef: '',
  lazy: false,
  tooltipMessage: '',
  leadingIcon: '',
  trailingIcon: '',
  preInput: false,
  postInput: false,
});

const emits = defineEmits(['update:inputValue', 'postInputClicked']);
// usage of lazy allows to simulate the validation onChange instead of onInput
function updateInput(e: Event) {
  if (props.lazy && e.type === 'change') {
    emits('update:inputValue', (e.target as HTMLInputElement).value);
  }
  if (!props.lazy && e.type === 'input') {
    emits('update:inputValue', (e.target as HTMLInputElement).value);
  }
}

const errorInputClasses = [
  'block',
  'w-full',
  'border-red-300',
  'text-red-900',
  'placeholder-red-300',
  '!ring-red-500',
  '!border-red-500',
  'sm:text-sm',
  'sm:leading-6',
  'rounded-md',
  'border-0',
  'py-1.5',
  'ring-1',
  'ring-inset',
  'focus:outline-none',
  'focus:ring-red-500',
  'focus:border-red-500',
  'focus:ring-1',
  'focus:ring-inset',
];
const baseInputClasses = ['block w-full min-w-0 flex-1 border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 truncate focus:ring-2 focus:ring-inset focus:ring-primary-400 sm:text-sm sm:leading-6 disabled:bg-neutral-100 disabled:text-neutral-500 disabled:cursor-not-allowed disabled:ring-neutral-300 disabled:placeholder:text-neutral-500'];

const inputClasses = computed(() => {
  const classes: any[] = [];

  classes.push(baseInputClasses);

  if (props.errorMessage || props.hasError) {
    classes.push(errorInputClasses);
  }

  if (props.preInput && !props.postInput) {
    classes.push('rounded-r-md');
  }

  if (props.postInput && !props.preInput) {
    classes.push('rounded-l-md');
  }

  if (props.leadingIcon) {
    classes.push('pl-8');
  }

  if (props.trailingIcon) {
    classes.push('pr-8');
  }

  if (!props.preInput && !props.postInput) {
    classes.push('rounded-md');
  }
  return classes;
});

const postInputClicked = () => {
  return emits('postInputClicked');
};
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="mb-1 block text-sm text-slate-700 font-medium leading-6"
    >
      {{ label }}
      <i-ph:info-bold
        v-if="tooltipMessage"
        v-tooltip="{
          content: tooltipMessage,
          placement: 'right',
        }"
        class="mb-[3px] ml-1 h-4 w-4 inline-flex text-slate-400"
        aria-hidden="true"
      />
    </label>

    <div class="relative flex rounded-md shadow-sm">
      <div v-if="props.preInput" class="inline-flex items-center rounded-l-md bg-white px-2 text-slate-500 ring-1 ring-slate-300 ring-inset -mr-[1px] sm:text-sm">
        <slot name="preInput" />
      </div>
      <span
        v-if="leadingIcon"
        :class="leadingIcon"
        class="absolute left-2 top-2 h-5 w-5 fill-current pr-1 text-center"
      />
      <input
        :id="name"
        :ref="passedRef"
        :type="type"
        :name="name"
        :class="inputClasses"
        :placeholder="placeholder"
        :value="inputValue"
        v-bind="$attrs"
        @input="updateInput"
        @change="updateInput"
      >
      <span
        v-if="trailingIcon || props.hasError"
        :class="[props.hasError ? 'i-heroicons:exclamation-circle-solid text-red-500' : trailingIcon]"
        class="absolute right-2 top-2 h-5 w-5 fill-current pl-1 text-center"
      />
      <button v-if="props.postInput" type="button" class="inline-flex items-center rounded-r-md bg-white px-2 text-slate-500 ring-1 ring-slate-300 ring-inset transition -ml-[1px] hover:bg-slate-100 sm:text-sm" @click="postInputClicked">
        <slot name="postInput" />
      </button>
    </div>
    <p
      v-if="errorMessage"
      :id="`${name}-error`"
      class="mt-2 text-sm text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
