import type { RouteLocationRaw } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useRouterLink(toName: string) {
  const userStore = useUserStore();
  const currentTeamId = computed(() => userStore?.currentTeam?.id || 0);
  const routeInfo = useRoute();
  return computed(() => {
    const baseSetup = {
      name: toName,
      params: {
        orgId: routeInfo.params?.orgId || currentTeamId.value,
      },
    } satisfies RouteLocationRaw;

    if (routeInfo.name === toName) {
      return {
        ...baseSetup,
        query: {
          ...routeInfo.query,
        },
      } satisfies RouteLocationRaw;
    }

    return baseSetup;
  });
}
