<script setup lang="ts">
import { useUserStore } from '@/stores/userStore';
import { useHead } from '@vueuse/head';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useHeadTitle } from '../composables/headTitle';
import { useRouterLink } from '../composables/router';

// setup
const routeInfo = useRoute();

// base setup
const userStore = useUserStore();
const baseURL = import.meta.env.VITE_API_BASE_URL;

// user/team setup
const currentUser = computed(() => userStore.currentUser);
const currentTeamId = computed(() => userStore?.currentTeam?.id || 0);
// dropdown setup

// menu navigation setup
const navigation = computed(() => [
  { name: 'Home', href: `${baseURL}/teams/${currentTeamId.value}/stats/campaigns`, current: false },
]);

const emailsLink = useRouterLink('EmailsIndex');
const reportsLink = useRouterLink('ReportIndex');
const eventsLink = useRouterLink('EventsIndex');
const contactsLink = useRouterLink('ContactsIndex');
const sourceIndexLink = useRouterLink('SourceIndex');
const surveysLink = useRouterLink('SurveysIndex');

const title = useHeadTitle();
useHead({ title });
</script>

<template>
  <a
    :href="navigation[0].href"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[navigation[0].current
      ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    :aria-current="navigation[0].current ? 'page' : undefined"
  >
    {{ navigation[0].name }}
  </a>
  <router-link
    v-if="routeInfo.params.orgId || currentTeamId || currentTeamId === 0"
    :to="emailsLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[/\/templates|\/scheduled_sends/.test($route.path)
      ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    aria-current="page"
  >
    Emails
  </router-link>
  <router-link
    :to="reportsLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[/\/reports|\/saved_reports/.test($route.path)
      ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    aria-current="page"
  >
    Reports
  </router-link>
  <router-link
    v-if="(currentUser?.list_management_enabled)
      && (userStore.ownerOrAdmin)
      && (routeInfo.params.orgId || currentTeamId || currentTeamId === 0)"
    :to="currentUser?.organization.active_contacts_count > 0 ? contactsLink : sourceIndexLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[/\/contacts|\/sources|\/custom_lists/.test($route.path) ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    aria-current="page"
  >
    Directory
  </router-link>
  <router-link
    v-if="currentUser?.events_management_enabled"
    :to="eventsLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[/\/events/.test($route.path)
      ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    aria-current="page"
  >
    Events
  </router-link>
  <router-link
    v-if="userStore.currentUser?.feature_flags?.ff_surveys_v2"
    :to="surveysLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[/\/teams\/\d+\/surveys/.test($route.path)
      ? 'border-primary-500 text-gray-900'
      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
    aria-current="page"
  >
    Surveys
  </router-link>
</template>
